'use client';

import './index.css';

import classNames from 'classnames';
import { forwardRef, useId } from 'react';

export const Field = forwardRef(
  (
    {
      as = 'input',
      theme = 'light',
      id = undefined,
      type = 'text',
      error,
      ...props
    },
    ref,
  ) => {
    const El = as;

    const generatedId = useId();
    const inputId = id || generatedId;

    return (
      <label
        htmlFor={inputId}
        className={classNames(
          'field',
          `field--as-${as}`,
          `field--theme-${theme}`,
        )}
      >
        <div className="field__border">
          <El
            ref={ref}
            // @ts-ignore
            className="field__input"
            id={inputId}
            type={type}
            {...props}
          />
        </div>

        {error ? <p className="field__error">{error}</p> : null}
      </label>
    );
  },
);

Field.displayName = 'Field';
