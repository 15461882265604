'use client';

import './index.css';
import './form.css';

import * as Sentry from '@sentry/nextjs';
import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from '@/components/button';
import { Field } from '@/components/field';
import { MaxWidth } from '@/components/max-width';
import { SupTitle } from '@/components/sup-title';

import { hasText, render } from '@/helpers/storyblok/rich-text';

export const Form = ({ blok }) => {
  const [error, setError] = useState(null);

  const TitleEl = blok.titleLevel || 'h2';

  const FormTitleEl = blok.formTitleLevel || 'h3';

  const { register, handleSubmit, formState } = useForm();

  const onSubmit = async (data) => {
    setError(null);

    try {
      const response = await fetch('/api/demo-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Demo form submit error');
      }

      Sentry.withScope((scope) => {
        scope.setExtras(data);
        Sentry.captureMessage('Demo form submitted successfully');
      });
    } catch (error) {
      setError(error);

      Sentry.withScope((scope) => {
        scope.setExtras(data);
        Sentry.captureException(error);
      });

      throw error;
    }
  };

  return (
    <div {...storyblokEditable(blok)} className={classNames('form')}>
      <MaxWidth className="form__max-width">
        <div>
          {blok.supTitle ? <SupTitle>{blok.supTitle}</SupTitle> : null}

          {blok.title ? (
            <TitleEl
              className="form__title"
              dangerouslySetInnerHTML={{
                __html: blok.title,
              }}
            />
          ) : null}

          {hasText(blok.content) ? (
            <div className="form__content desktop-only">
              {render(blok.content)}
            </div>
          ) : null}
        </div>

        <div>
          {blok.formTitle ? (
            <FormTitleEl
              className="form__form-title"
              dangerouslySetInnerHTML={{
                __html: blok.formTitle,
              }}
            />
          ) : null}

          {formState.isSubmitSuccessful ? (
            <div className="form__success">{render(blok.success)}</div>
          ) : (
            <>
              <form className="form__form" onSubmit={handleSubmit(onSubmit)}>
                {blok.lastNameField || blok.firstNameField ? (
                  <div className="form__line">
                    {blok.firstNameField ? (
                      <Field
                        placeholder={blok.firstNameField}
                        autoComplete="given-name"
                        {...register('firstName', {
                          required: true,
                        })}
                        error={
                          formState.errors.firstName
                            ? 'Veuillez renseigner votre prénom'
                            : null
                        }
                      />
                    ) : null}

                    {blok.lastNameField ? (
                      <Field
                        placeholder={blok.lastNameField}
                        autoComplete="family-name"
                        {...register('lastName', {
                          required: true,
                        })}
                        error={
                          formState.errors.lastName
                            ? 'Veuillez renseigner votre nom de famille'
                            : null
                        }
                      />
                    ) : null}
                  </div>
                ) : null}

                {blok.emailField || blok.phoneNumberField ? (
                  <div className="form__line">
                    {blok.emailField ? (
                      <Field
                        placeholder={blok.emailField}
                        type="email"
                        autoComplete="email"
                        {...register('email', {
                          required: true,
                        })}
                        error={
                          formState.errors.email
                            ? 'Veuillez renseigner votre e-mail'
                            : null
                        }
                      />
                    ) : null}

                    {blok.phoneNumberField ? (
                      <Field
                        placeholder={blok.phoneNumberField}
                        type="tel"
                        autoComplete="tel"
                        {...register('phoneNumber')}
                      />
                    ) : null}
                  </div>
                ) : null}

                {blok.companyField || blok.roleField ? (
                  <div className="form__line">
                    {blok.companyField ? (
                      <Field
                        placeholder={blok.companyField}
                        autoComplete="organization"
                        {...register('company', {
                          required: true,
                        })}
                        error={
                          formState.errors.company
                            ? 'Veuillez renseigner le nom de votre entreprise'
                            : null
                        }
                      />
                    ) : null}

                    {blok.roleField ? (
                      <Field
                        placeholder={blok.roleField}
                        autoComplete="role"
                        {...register('role', {
                          required: true,
                        })}
                        error={
                          formState.errors.role
                            ? 'Veuillez renseigner votre fonction'
                            : null
                        }
                      />
                    ) : null}
                  </div>
                ) : null}

                {blok.textField ? (
                  <div className="form__line">
                    <Field
                      as="textarea"
                      placeholder={blok.textField}
                      {...register('message')}
                    />
                  </div>
                ) : null}

                <Button
                  className="form__cta"
                  type="submit"
                  loading={formState.isSubmitting}
                >
                  {blok.submitLabel || 'Envoyer'}
                </Button>

                {error ? (
                  <p className="form__error">
                    Une erreur est survenue lors de l'envoi de votre formulaire,
                    veuillez réessayer plus tard.
                  </p>
                ) : null}
              </form>

              {hasText(blok.notice) ? (
                <div className="form__notice">{render(blok.notice)}</div>
              ) : null}
            </>
          )}

          {hasText(blok.content) ? (
            <div className="form__content mobile-only">
              {render(blok.content)}
            </div>
          ) : null}
        </div>
      </MaxWidth>
    </div>
  );
};
